<template lang="pug">
.alert(v-show="open", :class="['alert--' + type]")

  .alert--icon(v-if="icon")
    fa(:icon="icon")

  .alert--message
    slot

  button.alert--close-button(v-if="closable", @click="close")
    fa(:icon="['fas', 'close']")
</template>
<script setup lang="ts">
const props = defineProps({
  icon: {
    type: Array<String>
  },
  closable: Boolean,
  type: {
    type: String,
    default: 'info',
    validator: (value: string) => {
      return ['info', 'success', 'warning', 'error'].includes(value)
    }
  }
})

const open = ref(true)

const close = () => {
  open.value = false
}
</script>
<style lang="sass">
.alert
  display: flex
  flex-direction: row
  padding: 8px 16px
  border-radius: 4px
  background-color: #f4f4f4
  gap: 16px

  &--icon
    flex: 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  &--message
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    font-size: 14px
    line-height: 1.5

  &--close-button
    background: none
    color: inherit
    border: none
    font: inherit
    cursor: pointer
    outline: inherit
    flex: 0
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    padding: 8px

  a
    font-weight: bold
    text-decoration: underline

  &--info
    background-color: #e3f2fd

    *
      color: #2196f3

  &--success
    background-color: #e8f5e9

    *
      color: #4caf50

  &--warning
    background-color: #fff3e0

    *
      color: #ff9800

  &--error
    background-color: #ffebee

    *
      color: #f44336
</style>
